var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.warning = function () {};

exports.invariant = function () {};

{
  exports.warning = function (check, message) {
    if (!check && typeof console !== "undefined") {
      console.warn(message);
    }
  };

  exports.invariant = function (check, message) {
    if (!check) {
      throw new Error(message);
    }
  };
}
export default exports;
export const __esModule = exports.__esModule,
      warning = exports.warning,
      invariant = exports.invariant;